const BootstrapTabReplacement = {
  bind: function() {
    $('[data-bs-toggle="tab"').on('click', function() {
      const tab = $(this)
      tab.closest(".nav-tabs").find(".nav-link").removeClass("active")
      tab.addClass("active")

      const target = $(tab.data('bsTarget'));
      target.closest(".tab-content").find('.tab-pane').removeClass("active").removeClass("show")
      target.addClass("active show")

    })
  }
}

export default BootstrapTabReplacement 