import $ from "jquery"
global.jQuery = global.$ = $

import Rails from '@rails/ujs';
global.Rails = Rails
Rails.start();

import AutoSubmitForm from 'app/shared/auto-submit-form'
import BootstrapTabReplacement from 'app/shared/bootstrap-tab-replacement'

$(function() {
  AutoSubmitForm.bind();
  BootstrapTabReplacement.bind();
})
