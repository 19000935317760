// Auto submits remote rails remote forms when they change if the data-auto-submit attribute is set to true on the form
// - Form submissions are debounced with a delay of 200ms
// - Allows configuring of the debounce delay with a data-delay="300" on the form element
// - Allows howing and hiding of a spinner. Set content to hide with #auto-submit-content id, and set a spinner to show with #auto-submit-spinner id
const AutoSubmitForm = {
  bind: function() {
    // Need to bind to 'search' event to handle form field clear (clicking on the x to clear a form field) in chrome/others
    $('form[data-auto-submit="true"]').on('change keydown search', function() { 
      AutoSubmitForm.showSpinner(this);
      AutoSubmitForm.submit(this, $(this).data('delay') || 400)
    }).on('ajax:error', function() {
      AutoSubmitForm.hideSpinner(this);
      alert("An error occured when submitting your query.")
    }).on('ajax:success', 'form[data-auto-submit="true"]', function() {
      AutoSubmitForm.hideSpinner(this);
    })
  },
  showSpinner: function(form) {
    $($(form).data('spinner') || '#auto-submit-spinner').show();
    $($(form).data('content') || '#auto-submit-content').hide();
  },
  hideSpinner: function(form) {
    $($(form).data('spinner') || '#auto-submit-spinner').hide();
    $($(form).data('content') || '#auto-submit-content').show();
  },
  // Debounced submission of the form
  submit: function (form, debounceDelay) {
    clearTimeout(form.dataset.timeoutTimer)
    form.dataset.timeoutTimer = setTimeout(function() {
      Rails.fire(form, 'submit')
    }, debounceDelay);
  }
}

export default AutoSubmitForm